import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import * as React from "react";

import { ExtractionStatus, ExtractionStatusType } from "../ExtractionStatus";
import { IconButtonV3 } from "../IconButtonV3";
import Paginator from "../Paginator";

export type ConnectedApp = {
  id: string;
  name: string;
  account: string;
  status: "connected" | "expired";
};

type ConnectedAppsSectionProps = {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  apps: ConnectedApp[];
  navigateToPage: (page: number) => void;
  requestToDeleteOAuthCredential: (id: string) => Promise<void>;
};

function ConnectedAppsContent(
  props: ReturnType<typeof useConnectedAppsSectionState>
) {
  return (
    <div
      className={cn(
        "grid grid-row-subgrid row-span-[2/-1] grid-cols-subgrid col-span-4",
        "overflow-y-auto overflow-x-hidden h-[calc(100cqh-44px)] content-start"
      )}
    >
      {props.apps.map((app, index) => (
        <div
          key={`app-${index}`}
          className={cn(
            "grid grid-cols-subgrid col-span-4",
            "border-0 border-b-[1px] border-solid border-gray-200",
            "hover:bg-gray-50"
          )}
        >
          <div className="text-sm text-gray-900 px-[20px] py-[12px]">
            {app.name}
          </div>
          <div className="text-sm text-gray-900 px-[20px] py-[12px]">
            {app.account}
          </div>
          <div className="text-sm text-gray-900 px-[20px] py-[12px]">
            <div className="text-green-500">
              {app.status === "connected" ? (
                <ExtractionStatus
                  type={ExtractionStatusType.Approved}
                  textId="connect_apps_table.status.connected"
                />
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <ExtractionStatus
                    type={ExtractionStatusType.Failed}
                    textId="connect_apps_table.status.expired"
                    retryTextId="connect_apps_table.reconnect"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="text-sm">
            <div className="flex items-center justify-center h-full w-full">
              <IconButtonV3
                iconName="Trash"
                iconClassName="text-red-700"
                onClick={() => {
                  props.requestToDeleteOAuthCredential(app.id);
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function ConnectedAppsTable(props: ConnectedAppsSectionProps) {
  return (
    <div
      className={cn(
        "grid grid-cols-[1fr_1fr_1fr_minmax(0,82px)]",
        "grid-rows-[repeat(auto-fill,minmax(44px,44px))]",
        "border-0 border-solid border-gray-200 bg-white h-[100cqh] max-h-[100cqh]"
      )}
    >
      <div className="grid grid-cols-subgrid col-span-4 border-0 border-b-[1px] border-solid border-gray-200">
        <div className="text-sm text-gray-900 px-[20px] py-[12px] bg-gray-100">
          <FormattedMessage id="connect_apps_table.column.app_name" />
        </div>
        <div className="text-sm text-gray-900 px-[20px] py-[12px] bg-gray-100">
          <FormattedMessage id="connect_apps_table.column.account" />
        </div>
        <div className="text-sm text-gray-900 px-[20px] py-[12px] bg-gray-100">
          <FormattedMessage id="connect_apps_table.column.status" />
        </div>
        <div className="text-sm text-gray-900 px-[20px] py-[12px] bg-gray-100">
          <FormattedMessage id="connect_apps_table.column.action" />
        </div>
      </div>
      <ConnectedAppsContent {...props} />
    </div>
  );
}

function useConnectedAppsSectionState(props: ConnectedAppsSectionProps) {
  return React.useMemo(
    () => ({
      ...props,
    }),
    [props]
  );
}

export function ConnectedAppsSection(props: ConnectedAppsSectionProps) {
  const state = useConnectedAppsSectionState(props);
  return <ConnectedAppsSectionImpl {...state} />;
}

export function ConnectedAppsSectionImpl(
  props: ReturnType<typeof useConnectedAppsSectionState>
) {
  return (
    <div className="flex flex-col h-full w-full">
      <div
        className={cn(
          "h-[64px] min-h-[64px] flex flex-row items-center text-xl mx-[32px]",
          "border-0 border-b-1 border-solid border-gray-200 bg-white"
        )}
      >
        <FormattedMessage id="connect_apps_table.title" />
      </div>
      <div className={cn("flex-1 mx-[32px] overflow-y-hidden")}>
        <div className="h-full" style={{ ["containerType" as any]: "size" }}>
          <ConnectedAppsTable {...props} />
        </div>
      </div>
      {props.pageCount > 1 && (
        <div
          className={cn(
            "flex flex-col mx-[32px] items-end h-[58px] min-h-[58px]",
            "border-0 border-solid border-gray-200 bg-white"
          )}
        >
          <div className="self-end">
            <Paginator
              currentPage={props.currentPage}
              totalCount={props.pageCount}
              pageSize={props.pageSize}
              navigateToPage={props.navigateToPage}
            />
          </div>
        </div>
      )}
    </div>
  );
}
